<template>
  <div class="layout">
    <header>
      <div class="acea-row row-between-wrapper container">
        <div class="logo">
          <router-link to="/" v-if="system">
            <img :src="system.logo" alt="">
          </router-link>
        </div>
        <ul
          v-if="$route.path !== '/login'"
          class="acea-row menu"
        >
          <li :class="{ active: $route.path === '/product' }">
            <router-link to="/product">商品列表</router-link>
          </li>
          <li :class="{ active: $route.path === '/order' }">
            <router-link to="/order">我的订单</router-link>
          </li>
          <li v-if="!$store.getters.profile.token">
            <router-link to="/login">登录/注册</router-link>
          </li>
        </ul>
      </div>
    </header>
    <main>
      <div class="container">
        <keep-alive v-if="$route.meta.keepAlive">
          <router-view />
        </keep-alive>
        <router-view v-else />
      </div>
    </main>
    <footer class="acea-row row-column-center">
      <template v-if="system">
        <p>
          <span>联系电话：{{system.phone}}</span>
          <span>地址：{{system.address}}</span>
        </p>
        <p><a href="https://beian.miit.gov.cn/">{{system.beian}}</a></p>
      </template>
    </footer>
  </div>
</template>

<script>
import { ref } from 'vue'
import { getSystemConfig } from '@/api/system'

export default {
  name: 'layoutPage',
  setup() {
    const system = ref(null)
    getSystemConfig().then(res => {
      system.value = res.data
    })

    return {
      system
    }
  }
}
</script>

<style lang="less" scoped>
header {
  height: 96px;
  background-color: white;
  border-bottom: 1px solid #eee;
  .logo {
    max-width: 200px;
    height: 80px;
    a, img {
      width: 100%;
      height: 100%;
    }
  }
  .menu {
    li {
      font-size: 16px;
      margin: 0 15px;
      &.active {
        a {
          color: #e93323;
        }
      }
    }
  }
}

main {
  min-height: calc(100vh - 196px);
  padding: 15px 0;
}

footer {
  height: 100px;
  background-color: #F2F2F2;
  p {
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  span {
    margin-left: 40px;
    &:first-child {
      margin-left: 0;
    }
  }
}
</style>
